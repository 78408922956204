<template>
  <div class="order-dashboard">
    <div class="video-course" v-if="course">
      <div class="row">
        <div class="col-xl-12 col-lg-10 col-md-9 col-sm-12 col-12">
          <div class="dashboard-page-header pb-0">
            <h3 class="dashboard-page-title">{{course.name}}</h3>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12" v-if="section == 'introduction'">
          <h5 style="color:white;font-weight:600;">Terms and Conditions</h5>
          <p>By purchasing any product or service you agree that:</p>
          <p>1. You respect gender segregation as part of Islamic etiquettes especially sisters only products are purchased for females.</p>
          <p>2. A Muslim woman, do not address men. So please agree to protect your menfolk and your sisters. You will not allow any males over the age of 10 to hear any of the sisters only classes, or audios or contact meetings (as applicable to your purchase). Sisters will listen in a room where they are unable to hear or you will use headphones.</p>
          <p>(Permission is granted to allow your menfolk to read written material included in your purchase (PDF workbooks / class notes etc) in order to enhance your understanding and discussions together to raise  awareness as a team)</p>
          <p>All purchases from Fromyourtutor.com are for your own personal use only.<br>
          By purchasing any product or service you agree that:</p>
          <p>1. You will not share your product or service access with others.</p>
          <p>2. You will not resell any product or service</p>
          <p>3. You will not replicate the products, tools or strategies in your purchase, for your own business or non-profit.
          </p>
          <p>I trust that you will fear Allah to uphold this agreement and honor the rights of our team</p>
          <p>We look forward to meeting you inside our website  soon!<br>
          As soon as you sign up you'll have immediate access so you can get started right away إِنْ شَاء اَللّٰه </p>
          <p>Your login details are automatically emailed to you, please check your spam/junk folder or contact admin@fromyourtutor.com for any assistance.</p>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12" v-else>
          <!-- <video width="100%" height="440" controls>
            <source :src="video_module.video_url" type="video/mp4">
            <source :src="video_module.video_url" type="video/ogg">Your browser does not support the video tag.
          </video> -->
          <video-player :options="videoOptions" :key="video_module.id" @activated="confirmActivation"/>
          <p class="text-right"><a :href="video_module.resource_file_url" target="_blank" class="btn btn-default btn-sm text-white"><span class="dash-nav-icon"><i class="fas fa-download"></i></span> Download Resources</a></p>
          <p>{{video_module.description}}</p>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
          <h3>Modules</h3>
          <ol class="dashboard-modules">
            <li :class="section=='introduction'?'active-module':''"  v-on:click.prevent="getModuleDetails(0,'introduction')"><span>Introduction</span></li>
            <span v-if="modules.length">
              <li :class="((section=='module')&&(v_module.id == video_module.id))?'active-module':''" v-for="(v_module,index) in modules" :key="index" v-on:click.prevent="getModuleDetails(v_module.id,'module')">
                <span>{{v_module.name}}</span>
              </li>
            </span>
          </ol>
        </div>
      </div>
      <b-modal id="success" ref="alert-modal" :title="video_title" hide-footer centered>
        <div class="container text-center">
          <div class="icon-box text-center"><i class="far fa-lock"></i></div>
          <div class="modal-body text-center">
            <p>This video is blocked.</p>
          </div>				
          <div class="modal-footer justify-content-center" style="border-top:none;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal">Cancel</button>
        </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import VideoPlayer from '@/components/VideoPlayer.vue';
export default {
    name: 'ordervideoclassdetails',
    components: {
      VideoPlayer
    },
    data(){
      return{
        downloaded_materials:[],
        video_id:null,
        video_title:null,
        course:null,
        section:'introduction',
        modules:[],
        video_module:null,
        locked : false,
        videoOptions: {
          autoplay: false,
          controls: true,
          sources: [
            {
              src:'',
              type: 'video/mp4'
            }
          ]
        }
      }
    },
    beforeMount(){
      var bytes  = this.$CryptoJS.AES.decrypt(this.$route.params.key.replaceAll('Por21Ld','/'), 'tutn@369');
      var originalText = bytes.toString(this.$CryptoJS.enc.Utf8);
      var id =  parseInt(originalText.split('=')[1]);
      let bearer_token = 'Bearer eOA9wMqoPwfSj5AqAxQlxAFaEvSNUzI1NiJ9seyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3Q6ODAwMFwvYXBpXC9zdGF0aWNfYXV0aCIsImlhdCI6MTYyMDYzMTA3NSwiZXhwIjo5OTAxOTE5OTE1NSwibmJmIjoxNjIwNjMxMDc1JVBdgHaeCpQ1WVQwTXVoM0Zid0ZoY0RlIiwic3ViIjo0MDA4LCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.lBXcvw3IPM7XwXIrateRV4gIkjypGurV3h4UUkBUyHt'
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/order/video_modules/'+id+'/'+this.$route.params.transactionID, {headers:{"Authorization":bearer_token}}).then(res => {
        if(res.data.status == true){
          this.course = res.data.course;
          res.data.modules.forEach(v_module => {
            let module = v_module.module;
            module.active = v_module.is_active; 
            this.modules.push(module);
          })
        }else{
          this.course = null;
          this.modules = null;
        }
      });
    },
    methods:{
      hideModal(){
        this.$refs['alert-modal'].hide();
      },
      openVideoModal(videoID,title){
        this.video_id = videoID;
        this.video_title = title;
        this.$refs['video-modal'].show();
      },
      getModuleDetails(id,data){
        this.section = data;
        if(data == 'module'){
          this.video_module = this.modules.filter(v_module => {
            return (v_module.id == id);
          })[0];
          // if(this.video_module.active == 1){
          //   this.locked = false;
            this.videoOptions.sources[0].src = this.video_module.video_url;
          // }else{
          //   this.locked = true;
          // }
        }
      },
      confirmActivation(data){
        let index = this.modules.findIndex( v_module => v_module.id === data.module.id );
        this.modules[index].active = 1;
        this.getModuleDetails(this.modules[index].id,'module');
      }
    }
}
</script>

<style scoped>
.dashboard-list-block {
    background-color: #fff;
    margin-bottom: 20px;
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    border-radius: 3px;
}
.dashboard-list-content {
    font-size: 14px;
    /* padding-top: 23px; */
    /* padding-bottom: 23px; */
}
.dashboard-list-btn {
    padding-right: 29px;
    padding-top: 20px;
    text-align: right;
}
.dashboard-list-img img {
    width: 100%;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.title {
    color: #252328;
    text-decoration: none;
}
.title:hover {
    outline: 0;
    text-decoration: none;
}
.title:hover {
    color: #ce0d3d;
    text-decoration: none;
}
.btn-xs {
    padding: 4px 8px;
    font-size: 11px;
    line-height: 1.5;
}
.video-course{
  padding: 10px;
}
</style>